import { Autocomplete, TextField, Typography } from "@mui/material";
import React, { forwardRef, useMemo, useState } from "react";

const CAutocompleteMultiple = forwardRef(
  (
    {
      className,
      id,
      placeholder,
      options = [],
      value = [],
      onChange,
      multiple = false,
      errorText,
      sx,
      ...props
    },
    ref
  ) => {
    const [inputValue, setInputValue] = useState("");

    const currentValue = useMemo(() => {
      if (!value || (multiple && !Array.isArray(value)))
        return multiple ? [] : null;

      if (multiple) {
        return options.filter((option) =>
          value.some((v) =>
            typeof v === "object"
              ? option.id === v.id
              : option.id.toString() === v.toString()
          )
        );
      } else {
        return (
          options.find((option) =>
            typeof value === "object"
              ? option.id === value.id
              : option.id.toString() === value.toString()
          ) || null
        );
      }
    }, [multiple, options, value]);

    const onValueChange = (_event, newValue) => {
      if (!onChange) return;
      if (multiple) {
        onChange(_event, Array.isArray(newValue) ? newValue : []);
      } else {
        onChange(_event, newValue);
      }
    };

    return (
      <>
        <Autocomplete
          className={className}
          id={id}
          disableClearable={false}
          disablePortal
          options={options}
          getOptionLabel={(option) => option.label || option.name}
          value={currentValue}
          onChange={onValueChange}
          multiple={multiple}
          inputValue={inputValue}
          noOptionsText="No data found"
          onInputChange={(_event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={placeholder || "Choose..."}
              inputRef={ref}
            />
          )}
          sx={
            sx
              ? sx
              : {
                  background: "#fff",
                  borderRadius: "4px",
                  height: "42px",
                  "& .MuiFormLabel-root": { fontSize: "14px" },
                  "& .MuiInputBase-root .Mui-disabled": {
                    background: "e6e6e6",
                  },
                  "& .MuiInputBase-input": {
                    fontSize: "14px",
                    minWidth: "55px !important",
                    padding: "4px 4px 3.5px 2px !important",
                  },
                  "& .MuiOutlinedInput-notchedOutline": { height: "50px" },
                }
          }
          {...props}
        />
        {errorText ? (
          <Typography variant="body1">{errorText}</Typography>
        ) : (
          <></>
        )}
      </>
    );
  }
);

CAutocompleteMultiple.displayName = "CAutocompleteMultiple";

export default CAutocompleteMultiple;
